import "@/styles/globals.css";
import React from "react";
import type { AppProps } from "next/app";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { Analytics } from "@vercel/analytics/react";
import { OrgProvider } from "@/contexts/OrgContext";
import { UserbackProvider } from "@userback/react";
import { GoogleTagManager } from "@next/third-parties/google";

import { GeistSans } from "geist/font/sans";
import { GeistMono } from "geist/font/mono";

import { Auth0Provider } from "@auth0/auth0-react";
import Auth0Apollo from "@/components/Auth0Apollo";
import { Toaster } from "@/components/ui/toaster";

const userbackToken = process.env.NEXT_PUBLIC_USERBACK_TOKEN as string;
const apiAudience = process.env.NEXT_PUBLIC_API_AUDIENCE;
const auth0Domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN;
const clientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <div className={`${GeistSans.variable} ${GeistMono.variable} font-sans`}>
        <Auth0Provider
          domain={auth0Domain!}
          clientId={clientId!}
          cacheLocation="localstorage"
          authorizationParams={{
            audience: apiAudience,
            scope: "openid profile email offline_access read:current_user",
            redirect_uri: process.env.NEXT_PUBLIC_AUTH0_BASE_URL,
          }}
        >
          <UserbackProvider token={userbackToken}>
            <OrgProvider>
              <Auth0Apollo>
                <Toaster />
                <Component {...pageProps} />
              </Auth0Apollo>
            </OrgProvider>
          </UserbackProvider>
          <Analytics />
          <SpeedInsights />
          {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS && (
            <GoogleTagManager
              gtmId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}
            />
          )}
        </Auth0Provider>
      </div>
    </>
  );
}
